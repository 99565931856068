import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

const resField = `
    id
    type
    name
    fileName
    body
    showSignatureViewerWarning
    userPassword
    ownerPassword
    xid
    xidGenerated
    documentParentId
    ext
    useWatermark
    watermarkPictureXid
    watermarkPictureFileName
    watermarkText
    watermarkWithUser
    publishedOn
    publisherId
    toSign
	isUsed
    toEmail
    toSms
    toWhatsApp
    availableOnline
    createdAt
    status
    senderStatus
    userId
    isOnBoardingDocument
    version
    archived
    documentNextVersionId
    deliveryCurrentStep
    deliveryContractStrDate
    deliveryContractEndDate
    publisher {
        id
        name
        firstName
        fullName
    }
    childDocList {
        id
        type
        name
        fileName
        body
        userPassword
        ownerPassword
        xid
        xidGenerated
        ext
        useWatermark
        watermarkPictureXid
        watermarkPictureFileName
        watermarkText
        watermarkWithUser
        publishedOn
        publisherId
        toSign
        toEmail
        toSms
        toWhatsApp
        availableOnline
        status
        senderStatus
        userId
        publisher {
            id
            name
            firstName
            fullName
        }
        requireManagerApproval
        deliveryContractId
        firstDeliveryId
        firstDeliveryParentId
    }
    docValConfigStep1 {
        id
        createdAt
        updatedAt
        docId
        step
        toSign
        validationLevel
		userId
    }
    docValConfigStep2 {
        id
        createdAt
        updatedAt
        docId
        step
        toSign
		userId
        validationLevel
    }
    docValConfigStep3 {
        id
        createdAt
        updatedAt
        docId
        step
		userId
        toSign
        validationLevel
    }
    requireManagerApproval
    deliveryContractId
    firstDeliveryId
    firstDeliveryParentId
	docSignedXid
`;

export const newDocument = async (DocInput = null, ParentId = 0) => {
	try {
		let variables = {};

		if (!_.isUndefined(DocInput) && !_.isNil(DocInput)) {
			variables = {
				...variables,
				DocInput
			};
		}

		if (!_.isUndefined(DocInput) && !_.isNil(DocInput)) {
			variables = {
				...variables,
				ParentId: +ParentId
			};
		}

		// : {
		// 	DocInput,
		// 	ParentId: parseInt(ParentId)
		// }

		const MUTATION_NEW_DOCUMENT = gql`
            mutation ($DocInput: DocumentInput, $ParentId: ID) {
                NewDocument(
                    DocInput: $DocInput,
                    ParentId: $ParentId
                ) {
                    ${resField}
                }
            }
        `;

		const {
			data: { NewDocument }
		} = await apollo.mutate({
			mutation: MUTATION_NEW_DOCUMENT,
			variables
		});

		return NewDocument;
	} catch (error) {
		console.log(error);
	}
};

export const getDocument = async (documentId = 0, filter = {}, userId = null, contractId = null) => {
	try {
		let query = gql`
            query($Filter: DocumentFilterInput) {
                GetDocumentList(Filter: $Filter) {
                    ${resField}
                }
            }
        `;
		let variables = {
			Filter: {
				...filter,
				documentId: parseInt(documentId, 10),
				userId: parseInt(userId, 10),
				contractId: parseInt(contractId, 10)
			}
		};

		if (_.isNil(userId)) {
			delete variables.Filter.userId;
		}
		if (_.isNil(contractId)) {
			delete variables.Filter.contractId;
		}

		const {
			data: { GetDocumentList }
		} = await apollo.query({
			query,
			variables,
			fetchPolicy: 'no-cache'
		});

		return GetDocumentList[0];
	} catch (error) {
		console.log(error);
	}
};

export const updateDocument = async (documentId, documentInput, parentId = 0) => {
	try {
		const MUTATION_UPDATE_DOCUMENT = gql`
        mutation($documentId: ID!, $documentInput: DocumentInput!, $parentId: ID) {
                UpdDocument(DocumentId: $documentId, UpdatedDocument: $documentInput, ParentId: $parentId) {
                    ${resField}
                }
            }
        `;

		const {
			data: { UpdDocument }
		} = await apollo.mutate({
			mutation: MUTATION_UPDATE_DOCUMENT,
			variables: {
				documentId: parseInt(documentId, 10),
				documentInput,
				parentId: parseInt(parentId)
			}
		});

		return UpdDocument;
	} catch (error) {
		console.log(error);
	}
};

export const getDocumentList = async (filter = {}, projectId = null) => {
	try {
		let variables = {
			filter,
			projectId: !_.isNil(projectId) ? +projectId : null
		};
		let QUERY_GET_DOCUMENT_LIST = gql`
            query ($filter: DocumentFilterInput, $projectId: ID) {
                GetDocumentList(Filter: $filter, ProjectId: $projectId) {
                    ${resField}
                }
            }
        `;

		const {
			data: { GetDocumentList }
		} = await apollo.query({
			query: QUERY_GET_DOCUMENT_LIST,
			variables,
			fetchPolicy: 'no-cache'
		});

		return GetDocumentList;
	} catch (error) {
		console.error(error);
	}
};

export const duplicateDocument = async (documentId, version = 1) => {
	try {
		const MUTATION_DUPLICATE = gql`
            mutation ($documentId: ID!, $version: ID) {
                CopyDocumentOnBoardingPackage(DocumentId: $documentId, Version: $version) {
                    ${resField}
                }
            }
        `;

		const {
			data: { CopyDocumentOnBoardingPackage }
		} = await apollo.mutate({
			mutation: MUTATION_DUPLICATE,
			variables: {
				documentId: parseInt(documentId, 10),
				version
			}
		});

		return CopyDocumentOnBoardingPackage;
	} catch (error) {
		console.log(error);
	}
};

export const deleteDocumentPackage = async (documentId) => {
	try {
		const MUTATION_DELETE_DOCUMENT_PACKAGE = gql`
			mutation ($documentId: ID!) {
				DeleteOnBoardingPackageTree(DocumentId: $documentId)
			}
		`;

		const {
			data: { DeleteOnBoardingPackageTree }
		} = await apollo.mutate({
			mutation: MUTATION_DELETE_DOCUMENT_PACKAGE,
			variables: {
				documentId: parseInt(documentId, 10)
			}
		});

		return DeleteOnBoardingPackageTree;
	} catch (error) {
		console.log(error);
	}
};
/**
 * @param {String|Number} documentId
 */
export const deleteDocument = async (documentId) => {
	const mutation = gql`
		mutation ($documentId: ID!) {
			DelDocument(DocumentId: $documentId) {
				id
			}
		}
	`;
	const {
		data: { DelDocument }
	} = await apollo.mutate({
		mutation,
		variables: {
			documentId: parseInt(documentId, 10)
		}
	});

	return DelDocument;
};

export const addUpdDocValConfig = async (docValConfig) => {
	try {
		const MUTATION_ADD_UPD_DOC_VAL_CONFIG = gql`
			mutation ($docValConfig: [DocValConfigInput]!) {
				AddUpdDocValConfig(DocValConfig: $docValConfig)
			}
		`;

		const {
			data: { AddUpdDocValConfig }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_UPD_DOC_VAL_CONFIG,
			variables: {
				docValConfig
			}
		});

		return AddUpdDocValConfig;
	} catch (error) {
		console.log(error);
	}
};

export const delDocumentChild = async (parentId, childId) => {
	try {
		const MUTATION_DEL_DOCUMENT_CHILD = gql`
			mutation ($parentId: ID, $childId: ID) {
				DelDocumentChild(DocChildId: $childId, DocParentId: $parentId)
			}
		`;

		const {
			data: { DelDocumentChild }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_DOCUMENT_CHILD,
			variables: {
				parentId: parseInt(parentId),
				childId: parseInt(childId)
			}
		});

		return DelDocumentChild;
	} catch (error) {
		console.log(error);
	}
};
